import type { PostModel } from '@/models/Post/Model'
import PostsApi from '@/models/Post/Api'
import DetailsState from '@/helpers/models/DetailsState'
import ListState from '@/helpers/models/ListState'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export class PostDetailsState extends DetailsState<PostsApi, PostModel> {
	api = new PostsApi()
}

export function usePostDetailsState() {
	return new PostDetailsState()
}

export class PostListState extends ListState<
	PostsApi,
	PostModel,
	LaravelPaginationResponse<PostModel>
> {
	api = new PostsApi()
}

export function usePostListState() {
	return new PostListState()
}
