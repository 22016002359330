import { createRouter, createWebHistory } from 'vue-router'
import authGuard from './guards/authGuard'
import { useAuthStore } from '@/stores/Auth'

import Login from '../views/Auth/Login.vue'
import AdminApi from '@/helpers/api/AdminApi'
import RoleList from '../views/Role/List.vue'
import RoleEdit from '../views/Role/Edit.vue'
import PostList from '../views/Post/List.vue'
import PostEdit from '../views/Post/Edit.vue'
import CommentList from '../views/Comment/List.vue'
import CommentEdit from '../views/Comment/Edit.vue'
import TagList from '../views/Tag/List.vue'
import TagEdit from '../views/Tag/Edit.vue'
import CategoryList from '../views/Category/List.vue'
import CategoryEdit from '../views/Category/Edit.vue'
import ProductList from '../views/Product/List.vue'
import ProductEdit from '../views/Product/Edit.vue'
import OrderList from '../views/Order/List.vue'
import OrderEdit from '../views/Order/Edit.vue'
import CustomerList from '../views/Customer/List.vue'
import CustomerEdit from '../views/Customer/Edit.vue'
import InvoiceList from '../views/Invoice/List.vue'
import InvoiceEdit from '../views/Invoice/Edit.vue'
import PaymentList from '../views/Payment/List.vue'
import PaymentEdit from '../views/Payment/Edit.vue'
import SupplierList from '../views/Supplier/List.vue'
import SupplierEdit from '../views/Supplier/Edit.vue'
import ShipmentList from '../views/Shipment/List.vue'
import ShipmentEdit from '../views/Shipment/Edit.vue'
import ReviewList from '../views/Review/List.vue'
import ReviewEdit from '../views/Review/Edit.vue'
import DiscountList from '../views/Discount/List.vue'
import DiscountEdit from '../views/Discount/Edit.vue'
import UserList from '../views/User/List.vue'
import UserEdit from '../views/User/Edit.vue'

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: [
		{
			path: '/',
			redirect: '/roles',
		},
		{
			path: '/login',
			name: 'login',
			component: Login,
			beforeEnter: () => {
				const auth = useAuthStore()
				if (auth.user) {
					return { path: auth.lastRoute ?? '/posts' }
				}
			},
		},
		{
			path: '/logout',
			component: Login,
			beforeEnter: async () => {
				const auth = useAuthStore()
				await auth.logout()
				return { path: '/login' }
			},
		},
		{
			path: '/clear-data',
			component: Login,
			beforeEnter: async () => {
				await AdminApi.clearData()
				window.location.reload()
				throw new Error('Clear data')
			},
		},
		{
			path: '/roles',
			beforeEnter: authGuard,
			children: [
				{
					path: '',
					name: 'roles-list',
					component: RoleList,
				},
				{
					path: '/roles/create',
					name: 'roles-create',
					component: RoleEdit,
				},
				{
					path: '/roles/:id',
					name: 'roles-edit',
					component: RoleEdit,
				},
			],
		},
		{
			path: '/posts',
			beforeEnter: authGuard,
			children: [
				{
					path: '',
					name: 'posts-list',
					component: PostList,
				},
				{
					path: '/posts/create',
					name: 'posts-create',
					component: PostEdit,
				},
				{
					path: '/posts/:id',
					name: 'posts-edit',
					component: PostEdit,
				},
			],
		},
		{
			path: '/comments',
			beforeEnter: authGuard,
			children: [
				{
					path: '',
					name: 'comments-list',
					component: CommentList,
				},
				{
					path: '/comments/create',
					name: 'comments-create',
					component: CommentEdit,
				},
				{
					path: '/comments/:id',
					name: 'comments-edit',
					component: CommentEdit,
				},
			],
		},
		{
			path: '/tags',
			beforeEnter: authGuard,
			children: [
				{
					path: '',
					name: 'tags-list',
					component: TagList,
				},
				{
					path: '/tags/create',
					name: 'tags-create',
					component: TagEdit,
				},
				{
					path: '/tags/:id',
					name: 'tags-edit',
					component: TagEdit,
				},
			],
		},
		{
			path: '/categories',
			beforeEnter: authGuard,
			children: [
				{
					path: '',
					name: 'categories-list',
					component: CategoryList,
				},
				{
					path: '/categories/create',
					name: 'categories-create',
					component: CategoryEdit,
				},
				{
					path: '/categories/:id',
					name: 'categories-edit',
					component: CategoryEdit,
				},
			],
		},
		{
			path: '/products',
			beforeEnter: authGuard,
			children: [
				{
					path: '',
					name: 'products-list',
					component: ProductList,
				},
				{
					path: '/products/create',
					name: 'products-create',
					component: ProductEdit,
				},
				{
					path: '/products/:id',
					name: 'products-edit',
					component: ProductEdit,
				},
			],
		},
		{
			path: '/orders',
			beforeEnter: authGuard,
			children: [
				{
					path: '',
					name: 'orders-list',
					component: OrderList,
				},
				{
					path: '/orders/create',
					name: 'orders-create',
					component: OrderEdit,
				},
				{
					path: '/orders/:id',
					name: 'orders-edit',
					component: OrderEdit,
				},
			],
		},
		{
			path: '/customers',
			beforeEnter: authGuard,
			children: [
				{
					path: '',
					name: 'customers-list',
					component: CustomerList,
				},
				{
					path: '/customers/create',
					name: 'customers-create',
					component: CustomerEdit,
				},
				{
					path: '/customers/:id',
					name: 'customers-edit',
					component: CustomerEdit,
				},
			],
		},
		{
			path: '/invoices',
			beforeEnter: authGuard,
			children: [
				{
					path: '',
					name: 'invoices-list',
					component: InvoiceList,
				},
				{
					path: '/invoices/create',
					name: 'invoices-create',
					component: InvoiceEdit,
				},
				{
					path: '/invoices/:id',
					name: 'invoices-edit',
					component: InvoiceEdit,
				},
			],
		},
		{
			path: '/payments',
			beforeEnter: authGuard,
			children: [
				{
					path: '',
					name: 'payments-list',
					component: PaymentList,
				},
				{
					path: '/payments/create',
					name: 'payments-create',
					component: PaymentEdit,
				},
				{
					path: '/payments/:id',
					name: 'payments-edit',
					component: PaymentEdit,
				},
			],
		},
		{
			path: '/suppliers',
			beforeEnter: authGuard,
			children: [
				{
					path: '',
					name: 'suppliers-list',
					component: SupplierList,
				},
				{
					path: '/suppliers/create',
					name: 'suppliers-create',
					component: SupplierEdit,
				},
				{
					path: '/suppliers/:id',
					name: 'suppliers-edit',
					component: SupplierEdit,
				},
			],
		},
		{
			path: '/shipments',
			beforeEnter: authGuard,
			children: [
				{
					path: '',
					name: 'shipments-list',
					component: ShipmentList,
				},
				{
					path: '/shipments/create',
					name: 'shipments-create',
					component: ShipmentEdit,
				},
				{
					path: '/shipments/:id',
					name: 'shipments-edit',
					component: ShipmentEdit,
				},
			],
		},
		{
			path: '/reviews',
			beforeEnter: authGuard,
			children: [
				{
					path: '',
					name: 'reviews-list',
					component: ReviewList,
				},
				{
					path: '/reviews/create',
					name: 'reviews-create',
					component: ReviewEdit,
				},
				{
					path: '/reviews/:id',
					name: 'reviews-edit',
					component: ReviewEdit,
				},
			],
		},
		{
			path: '/discounts',
			beforeEnter: authGuard,
			children: [
				{
					path: '',
					name: 'discounts-list',
					component: DiscountList,
				},
				{
					path: '/discounts/create',
					name: 'discounts-create',
					component: DiscountEdit,
				},
				{
					path: '/discounts/:id',
					name: 'discounts-edit',
					component: DiscountEdit,
				},
			],
		},
		{
			path: '/users',
			beforeEnter: authGuard,
			children: [
				{
					path: '',
					name: 'users-list',
					component: UserList,
				},
				{
					path: '/users/create',
					name: 'users-create',
					component: UserEdit,
				},
				{
					path: '/users/:id',
					name: 'users-edit',
					component: UserEdit,
				},
			],
		},
		{
			path: '/:pathMatch(.*)*',
			redirect: '/login',
		},
	],
})

export default router
