<template>
	<div
		class="form-input"
		:class="{ 'form-input--error': errorMessage }">
		<div class="form-input__prepend-label-container">
			<slot name="prepend-label" />
		</div>
		<div class="form-input__label-container">
			<slot
				:input-id="String(uuid)"
				name="label">
				<label :for="String(uuid)">{{ labelText }}</label>
			</slot>
		</div>
		<div
			ref="inputContainer"
			class="form-input__input-container">
			<slot />
		</div>
		<div class="form-input__error-message-container">
			<slot name="error-message">
				<small
					v-if="errorMessage"
					class="form-input__error-message"
					>{{ errorMessage }}</small
				>
			</slot>
		</div>
	</div>
</template>

<script setup lang="ts">
import { ref, computed, getCurrentInstance, onMounted } from 'vue'

const props = defineProps<{
	errorMessage?: string
	required?: boolean
	label: string
}>()

const inputContainer = ref<HTMLElement | null>(null)
const instance = getCurrentInstance()
const uuid = ref(instance!.uid)

const labelText = computed(() => {
	return props.required ? `${props.label} *` : props.label
})

onMounted(() => {
	if (!inputContainer.value) return
	const inputElement = inputContainer.value.querySelector('input')
	if (inputElement) {
		inputElement.id = String(uuid.value)
	}
})
</script>

<style scoped lang="scss">
.form-input {
	display: flex;
	flex-direction: column;
	gap: 5px;

	&--error {
		:deep(.form-input__input-container) {
			.p-inputtext,
			.p-cascadeselect,
			.p-checkbox,
			.p-colorpicker,
			.p-datepicker,
			.p-editor,
			.p-inputnumber,
			.p-inputotp-input,
			.p-listbox,
			.p-multiselect,
			.p-radiobutton-input,
			.p-select,
			.p-selectbutton,
			.p-textarea,
			.p-togglebutton,
			.p-toggleswitch .p-toggleswitch-slider,
			.p-tree-select {
				border: 1px solid var(--p-red-500);
			}

			.p-slider .p-slider-handle,
			.p-slider .p-slider-handle::before {
				background-color: var(--p-red-500);
			}

			.p-knob svg .p-knob-range {
				stroke: var(--p-red-500);
			}
			.p-knob svg .p-knob-text,
			.p-rating svg path {
				fill: var(--p-red-500);
			}
		}
	}

	.form-input__label-container {
		display: flex;
		align-items: flex-start;
		gap: 5px;
		flex-direction: column;
	}

	.form-input__input-container {
		> * {
			width: 100%;
		}
	}

	.form-input__error-message-container {
		display: flex;
		align-items: flex-start;
		gap: 5px;
		flex-direction: column;

		.form-input__error-message {
			color: var(--p-red-500);
		}
	}
}
</style>
