<template>
	<div
		class="navigation"
		:class="{ 'navigation--expanded': locked }"
		@mouseover="isSidebarOpen = true"
		@mouseleave="isSidebarOpen = false">
		<div class="navigation__header">
			<Avatar :label="initials" />
			<span class="navigation__email">{{ auth.user!.email }}</span>
			<Button
				severity="secondary"
				:icon="lockIcon"
				text
				@click="toggleLocked" />
		</div>
		<div class="navigation__links-container">
			<router-link
				class="navigation__link-item"
				to="/roles">
				<i class="navigation__link-icon far fa-light fa-user-shield"></i>
				<div class="navigation__link-title">Roles</div>
			</router-link>
			<router-link
				class="navigation__link-item"
				to="/posts">
				<i class="navigation__link-icon far fa-light fa-file-alt"></i>
				<div class="navigation__link-title">Posts</div>
			</router-link>
			<router-link
				class="navigation__link-item"
				to="/comments">
				<i class="navigation__link-icon far fa-light fa-comments"></i>
				<div class="navigation__link-title">Comments</div>
			</router-link>
			<router-link
				class="navigation__link-item"
				to="/tags">
				<i class="navigation__link-icon far fa-light fa-tags"></i>
				<div class="navigation__link-title">Tags</div>
			</router-link>
			<router-link
				class="navigation__link-item"
				to="/categories">
				<i class="navigation__link-icon far fa-light fa-folder"></i>
				<div class="navigation__link-title">Categories</div>
			</router-link>
			<router-link
				class="navigation__link-item"
				to="/products">
				<i class="navigation__link-icon far fa-light fa-box-open"></i>
				<div class="navigation__link-title">Products</div>
			</router-link>
			<router-link
				class="navigation__link-item"
				to="/orders">
				<i class="navigation__link-icon far fa-light fa-receipt"></i>
				<div class="navigation__link-title">Orders</div>
			</router-link>
			<router-link
				class="navigation__link-item"
				to="/customers">
				<i class="navigation__link-icon far fa-light fa-users"></i>
				<div class="navigation__link-title">Customers</div>
			</router-link>
			<router-link
				class="navigation__link-item"
				to="/invoices">
				<i class="navigation__link-icon far fa-light fa-file-invoice-dollar"></i>
				<div class="navigation__link-title">Invoices</div>
			</router-link>
			<router-link
				class="navigation__link-item"
				to="/payments">
				<i class="navigation__link-icon far fa-light fa-credit-card"></i>
				<div class="navigation__link-title">Payments</div>
			</router-link>
			<router-link
				class="navigation__link-item"
				to="/suppliers">
				<i class="navigation__link-icon far fa-light fa-truck"></i>
				<div class="navigation__link-title">Suppliers</div>
			</router-link>
			<router-link
				class="navigation__link-item"
				to="/shipments">
				<i class="navigation__link-icon far fa-light fa-shipping-fast"></i>
				<div class="navigation__link-title">Shipments</div>
			</router-link>
			<router-link
				class="navigation__link-item"
				to="/reviews">
				<i class="navigation__link-icon far fa-light fa-star"></i>
				<div class="navigation__link-title">Reviews</div>
			</router-link>
			<router-link
				class="navigation__link-item"
				to="/discounts">
				<i class="navigation__link-icon far fa-light fa-tags"></i>
				<div class="navigation__link-title">Discounts</div>
			</router-link>
			<router-link
				class="navigation__link-item"
				to="/users">
				<i class="navigation__link-icon far far fa-user"></i>
				<div class="navigation__link-title">Users</div>
			</router-link>
			<router-link
				class="navigation__link-item"
				to="/logout">
				<i class="navigation__link-icon far fa-arrow-right-from-bracket"></i>
				<div class="navigation__link-title">Logout</div>
			</router-link>
			<router-link
				class="navigation__link-item"
				to="/clear-data">
				<i class="navigation__link-icon far fa-database"></i>
				<div class="navigation__link-title">Clear Data</div>
			</router-link>
		</div>
		<div class="navigation__logo-container">
			<img
				src="@/assets/logo.svg"
				alt="logo" />
		</div>
	</div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { useAuthStore } from '@/stores/Auth'
import Button from 'primevue/button'
import Avatar from 'primevue/avatar'

const auth = useAuthStore()
const locked = ref(localStorage.getItem('sidebarLocked') === 'true')
const isSidebarOpen = ref(false)

const initials = computed(
	() => auth.user!.email[0].toUpperCase() + auth.user!.email[1].toUpperCase(),
)
const lockIcon = computed(() => {
	if (locked.value) {
		return 'fal fa-lock'
	} else {
		return 'fal fa-lock-open'
	}
})

function toggleLocked() {
	locked.value = !locked.value
	localStorage.setItem('sidebarLocked', locked.value.toString())
}
</script>

<style scoped lang="scss">
.navigation {
	border-right: 1px solid var(--p-surface-200);
	display: flex;
	flex-direction: column;
	overflow: hidden;
	width: 52px;
	transition: width 0.3s;
	background-color: var(--p-surface-0);

	@media (prefers-color-scheme: dark) {
		border-right-color: var(--p-surface-900);
		background-color: var(--p-surface-950);
	}

	&.navigation--expanded,
	&:hover {
		width: 255px;

		.navigation__logo-container {
			padding: 30px;
		}
	}

	.navigation__header {
		display: flex;
		align-items: center;
		padding: 10px;
		gap: 10px;
		border-bottom: 1px solid var(--p-surface-200);
		width: 255px;
		height: 64px;

		@media (prefers-color-scheme: dark) {
			border-bottom-color: var(--p-surface-950);
		}

		.navigation__email {
			flex: 1;
		}
	}

	.navigation__links-container {
		width: 255px;
		flex: 1;

		.navigation__link-item {
			display: flex;
			align-items: center;
			padding: 10px;
			gap: 10px;
			color: var(--p-text-color);
			text-decoration: none;

			&:hover {
				background-color: var(--p-surface-100);

				@media (prefers-color-scheme: dark) {
					background-color: var(--p-surface-900);
				}
			}

			&.router-link-active {
				background-color: var(--p-surface-100);

				@media (prefers-color-scheme: dark) {
					background-color: var(--p-surface-900);
				}
			}

			.navigation__link-title {
				flex: 1;
			}

			.navigation__link-icon {
				font-size: 22px;
				color: var(--p-surface-500);
				width: 32px;
				display: flex;
				justify-content: center;

				@media (prefers-color-scheme: dark) {
					color: var(--p-surface-400);
				}
			}
		}
	}

	.navigation__logo-container {
		padding: 8px;
	}
}
</style>
