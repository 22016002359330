import type { DiscountModel } from '@/models/Discount/Model'
import DiscountsApi from '@/models/Discount/Api'
import DetailsState from '@/helpers/models/DetailsState'
import ListState from '@/helpers/models/ListState'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export class DiscountDetailsState extends DetailsState<DiscountsApi, DiscountModel> {
	api = new DiscountsApi()
}

export function useDiscountDetailsState() {
	return new DiscountDetailsState()
}

export class DiscountListState extends ListState<
	DiscountsApi,
	DiscountModel,
	LaravelPaginationResponse<DiscountModel>
> {
	api = new DiscountsApi()
}

export function useDiscountListState() {
	return new DiscountListState()
}
