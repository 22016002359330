import type { ReviewModel, ReviewStorePayload, ReviewUpdatePayload } from '@/models/Review/Model'
import Api from '@/helpers/models/Api'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export default class ReviewsApi extends Api<
	ReviewModel,
	LaravelPaginationResponse<ReviewModel>,
	ReviewStorePayload,
	ReviewUpdatePayload
> {
	route = 'reviews'
}
