import type { PostModel, PostStorePayload, PostUpdatePayload } from '@/models/Post/Model'
import Api from '@/helpers/models/Api'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export default class PostsApi extends Api<
	PostModel,
	LaravelPaginationResponse<PostModel>,
	PostStorePayload,
	PostUpdatePayload
> {
	route = 'posts'
}
