import type { ReviewModel } from '@/models/Review/Model'
import ReviewsApi from '@/models/Review/Api'
import DetailsState from '@/helpers/models/DetailsState'
import ListState from '@/helpers/models/ListState'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export class ReviewDetailsState extends DetailsState<ReviewsApi, ReviewModel> {
	api = new ReviewsApi()
}

export function useReviewDetailsState() {
	return new ReviewDetailsState()
}

export class ReviewListState extends ListState<
	ReviewsApi,
	ReviewModel,
	LaravelPaginationResponse<ReviewModel>
> {
	api = new ReviewsApi()
}

export function useReviewListState() {
	return new ReviewListState()
}
