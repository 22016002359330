<template>
	<div class="container">
		<slot></slot>
	</div>
</template>

<style scoped lang="scss">
.container {
	width: 100%;
	padding: 24px 10%;
}
</style>
