<template>
	<FormContainer
		class="form"
		:visible
		:title="props.isEdit ? 'Update Payment' : 'Create Payment'"
		:as-dialog
		@close="emit('close')">
		<form @submit.prevent="submit">
			<FormInput
				:required="true"
				:error-message="formErrors.payment_date"
				label="Payment Date">
				<DatePicker
					v-model="formData.payment_date"
					:disabled="!!props.forceValues.payment_date" />
			</FormInput>
			<FormInput
				:required="true"
				:error-message="formErrors.amount"
				label="Amount">
				<InputNumber
					v-model="formData.amount"
					:disabled="!!props.forceValues.amount"
					:max-fraction-digits="2"
					:max="10000000000" />
			</FormInput>
			<FormInput
				:required="false"
				:error-message="formErrors.payment_method"
				label="Payment Method">
				<Select
					v-model="formData.payment_method"
					:options="[
						{ title: 'CREDIT CARD', value: 'CREDIT CARD' },
						{ title: 'PAYPAL', value: 'PAYPAL' },
						{ title: 'BANK TRANSFER', value: 'BANK TRANSFER' },
					]"
					:show-clear="true"
					:disabled="!!props.forceValues.payment_method"
					option-label="title"
					option-value="value" />
			</FormInput>
			<FormInput
				:required="false"
				:error-message="formErrors.order_id"
				label="Order">
				<ModelSelect
					v-model="formData.order_id"
					:api="new OrdersApi()"
					:disabled="!!props.forceValues.order_id"
					option-label="order_date" />
			</FormInput>
			<div class="form__footer-container">
				<Button
					v-if="props.isEdit && !props.hideRemove"
					severity="danger"
					icon="fal fa-trash"
					label="Remove"
					outlined
					:loading="loading"
					@click="remove" />
				<Button
					icon="fal fa-save"
					:loading="loading"
					:label="props.isEdit ? 'Update' : 'Create'"
					@click="submit"
					@submit.stop />
			</div>
		</form>
	</FormContainer>
</template>

<script setup lang="ts">
import { watch } from 'vue'
import { useRouter } from 'vue-router'
import PaymentsApi from '@/models/Payment/Api'
import type { Payment } from '@/models/Payment/Model'
import { useForm } from '@/helpers/form'
import FormInput from '@/components/FormInput.vue'
import Button from 'primevue/button'
import FormContainer from '@/components/FormContainer.vue'
import DatePicker from 'primevue/datepicker'
import InputNumber from 'primevue/inputnumber'
import InputText from 'primevue/inputtext'
import ModelSelect from '@/components/ModelSelect.vue'
import OrdersApi from '@/models/Order/Api'
import Select from 'primevue/select'

type FormData = {
	payment_date: string
	amount: number
	payment_method: string
	order_id: number
}

const emit = defineEmits([
	'start-loading',
	'stop-loading',
	'close',
	'created',
	'updated',
	'deleted',
])

const props = withDefaults(
	defineProps<{
		isEdit?: boolean
		id?: Payment['id']
		hideInputs?: (keyof FormData)[]
		defaultValues?: Partial<FormData>
		forceValues?: Partial<FormData>
		shouldRedirect?: boolean
		attachTo?: Record<string, { method: 'associate' | 'syncWithoutDetaching'; id: string | number }>
		asDialog?: boolean
		visible?: boolean
		hideRemove?: boolean
	}>(),
	{
		id: undefined,
		hideInputs: () => [],
		defaultValues: () => ({}),
		forceValues: () => ({}),
		shouldRedirect: true,
		attachTo: undefined,
		asDialog: false,
		visible: false,
		hideRemove: false,
	},
)

const router = useRouter()
const { formData, loading, formErrors, reset, submit, remove, isDirty } = useForm({
	api: new PaymentsApi(),
	defaultData: () => ({
		payment_date: '',
		amount: 0,
		payment_method: '',
		order_id: 0,
	}),
	forceValues: props.forceValues,
	attachTo: props.attachTo,
	isEdit: props.isEdit,
	id: props.id,
	onStartLoading: () => emit('start-loading'),
	onStopLoading: () => emit('stop-loading'),
	onClose: () => emit('close'),
	onCreated: (entity) => {
		if (props.shouldRedirect) {
			router.push({ name: 'payments-edit', params: { id: entity!.id } })
		}
		emit('created', entity)
	},
	onUpdated: () => emit('updated'),
	onDeleted: () => emit('deleted'),
})

watch(
	() => props.visible,
	(val) => {
		if (val) reset()
	},
)
</script>

<style lang="scss">
.form {
	form {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 10px;

		& > * {
			width: 100%;
		}

		.form__footer-container {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			gap: 10px;
		}

		&--edit {
			.form__footer-container {
				justify-content: space-between;
			}
		}
	}
}
</style>
