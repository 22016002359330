import type {
	DiscountModel,
	DiscountStorePayload,
	DiscountUpdatePayload,
} from '@/models/Discount/Model'
import Api from '@/helpers/models/Api'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export default class DiscountsApi extends Api<
	DiscountModel,
	LaravelPaginationResponse<DiscountModel>,
	DiscountStorePayload,
	DiscountUpdatePayload
> {
	route = 'discounts'
}
