import type {
	CommentModel,
	CommentStorePayload,
	CommentUpdatePayload,
} from '@/models/Comment/Model'
import Api from '@/helpers/models/Api'
import type { LaravelPaginationResponse } from '@/interfaces/models/Laravel'

export default class CommentsApi extends Api<
	CommentModel,
	LaravelPaginationResponse<CommentModel>,
	CommentStorePayload,
	CommentUpdatePayload
> {
	route = 'comments'
}
