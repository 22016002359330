<template>
	<Header :title="isEdit ? 'Edit Order' : 'Create Order'" />
	<ProgressBar
		v-if="loaders.size > 0"
		mode="indeterminate"
		class="edit__progress-bar" />
	<Container class="edit">
		<Form
			:id="route.params.id as string"
			:is-edit="isEdit"
			@start-loading="loaders.add('form')"
			@stop-loading="loaders.delete('form')"
			@deleted="router.push({ name: 'orders-list' })" />
		<ProductsRelationWidget
			v-if="isEdit"
			:order-id="route.params.id as string"
			@start-loading="loaders.add('Products')"
			@stop-loading="loaders.delete('Products')" />
		<InvoiceRelationWidget
			v-if="isEdit"
			:order-id="route.params.id as string"
			@start-loading="loaders.add('Invoice')"
			@stop-loading="loaders.delete('Invoice')" />
		<PaymentsRelationWidget
			v-if="isEdit"
			:order-id="route.params.id as string"
			@start-loading="loaders.add('Payments')"
			@stop-loading="loaders.delete('Payments')" />
		<ShipmentRelationWidget
			v-if="isEdit"
			:order-id="route.params.id as string"
			@start-loading="loaders.add('Shipment')"
			@stop-loading="loaders.delete('Shipment')" />
	</Container>
</template>

<script setup lang="ts">
import { reactive, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import Header from './components/Header.vue'
import Form from './components/Form.vue'
import ProgressBar from 'primevue/progressbar'
import Container from '@/components/Container.vue'
import ProductsRelationWidget from './components/ProductsRelationWidget.vue'
import InvoiceRelationWidget from './components/InvoiceRelationWidget.vue'
import PaymentsRelationWidget from './components/PaymentsRelationWidget.vue'
import ShipmentRelationWidget from './components/ShipmentRelationWidget.vue'

const route = useRoute()
const router = useRouter()
const isEdit = computed(() => route.name === 'orders-edit')
const loaders = reactive(new Set<string>())
</script>

<style lang="scss" scoped>
.edit__progress-bar {
	height: 4px;
	margin-bottom: -4px;
	width: 100%;
	border-radius: 0;
}

.edit {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
	gap: 20px;
	justify-items: center;
	align-items: start;
}
</style>
